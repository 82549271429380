import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.secondary};
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 500;
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.text};
    }
  }
`

const Footer = () => (
  <Wrapper>
    <List>
    <Item>
    <div class="suodatin2">
    <center><h4>Uhkapelaaminen voi aiheuttaa riippuvuutta</h4></center>
<p>Aseta itsellesi budjetti ja pelirajat - ja pidä niistä kiinni. Älä koskaan yritä paikata tappioitasi pelaamalla enemmän.</p>
<p>Jos tunnet menettäneesi kontrollin pelaamiseesi, hakeudu nopeasti apuun.</p>
<p>Auttavat palvelut:</p>
<p><a href="https://www.peluuri.fi/fi" TARGET="_blank">www.peluuri.fi</a></p>
<p><a href="https://www.veikkaus.fi/fi/pelaamaltilla" TARGET="_blank">www.pelaamaltilla.fi</a></p>
<p>Peluurin puhelin: <b>0800 100 101</b></p>
<p>K18 - Sivustomme ja täällä esitellyt kasinot ovat tarkoitettu vain täysi-ikäisille pelaajille.</p>
</div>
      </Item>
      <Item>
      </Item>
      <Item>
      </Item>
      <Item>
      <center><img
            className='lazyload'
            data-src="/images/vastuullinen-pelaaminen.webp"
            width='240'
            height='152'
            alt="K18 - vastuullista pelaamista"
            title="K18 - vastuullista pelaamista"
          /></center>
      <center><p>contact@uusinettikasino.com</p></center>
      </Item>
    </List>
  </Wrapper>
)

export default Footer
